import React from "react";

const AboutUs = () => {
    return(
        <div className="homeAboutUsSection section">
            <h2 className="sectionTitle">About Us</h2>
            <p className="sectionContent">
                We are game developers with expertise in developing learning games. We build competitive GAME BASED LEARNING solutions.
                Our team comprises of game developers, designers, Pedagogy experts and Academic Subject matter experts.
            </p>
        </div>
    )
}

export default AboutUs;