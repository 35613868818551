import React from "react";

import parentapp from '../../../assets/ParentConnectApp.png'
import learningGames from '../../../assets/GameBasedAssignments.png'
import teacher from '../../../assets/Classrooms.png'

const Features = () => {
    return(
        <div className="featuresSection">
            <h1 className="sectionTitle">Features</h1>
            <div className="featuresDiv">
                <div className="feature">
                    <div className="featureTextDiv">
                        <p className="featureTitle">Pack of 18 Activity games</p>
                        <p className="featureContent">
                            A separate application to link children, creating groups, play vs, create challenges customize content etc.
                        </p>
                    </div>
                    <div className="featureImgDiv">
                        <img className="featureImg" src={parentapp} alt="Parent Connect App"/>
                    </div>
                </div>
                <div className="feature">
                    <div className="featureTextDiv">
                        <p className="featureTitle">Enticing Graphics and amazing colors</p>
                        <p className="featureContent">60+ unique learning games that cover all Math, Language and cognitive skills.</p>
                    </div>
                    <div className="featureImgDiv">
                        <img className="featureImg" src={learningGames} alt="Learning Games"/>
                    </div>
                </div>
                <div className="feature">
                    <div className="featureTextDiv">
                        <p className="featureTitle">Self Explanatory</p>
                        <p className="featureContent">A separate application to link children, creating groups, play vs, create challenges customize content etc.</p>
                    </div>
                    <div className="featureImgDiv">
                        <img className="featureImg" src={teacher} alt="Teacher Dashboard"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Features;