import React from "react";
import banner from '../../../assets/TinyNinzaBanner.png'


const Banner = () => {
    return(
        <div className="tinyNinzaBanner">
            <img className="pageBanner" src={banner} alt="TinyNinza"/>
        </div>
    );
}

export default Banner;