import React from "react";
import contactBG from '../../../assets/Asset 7.png'


const Contact = () => {
    return(
        <div className="homeContactUsSection">
            <img className="contactBG" src={contactBG} alt="Happy Adda"/>
            <div className="innerText">
                <form className="homeContactForm">
                    <input className="homeContactInput" placeholder="Name"/>
                    <input className="homeContactInput" placeholder="Email"/>
                    <input className="homeContactInput" placeholder="Message"/>
                    <div className="homeContactButton"><p>SUBMIT</p></div>
                </form>
                <div className="contactContent">
                    <h2 className="sectionTitle">Contact Us</h2>
                    <p className="sectionContent">HAPPY ADDA STUDIOS</p>
                    <p className="sectionContent">Email: ping@happyadda.com</p>
                    <p className="sectionContent">Web: https://happyadda.com</p>
                    <p className="sectionContent">Contact Number: +91-80-35843699</p>
                </div>
            </div>
        </div>
    )
}

export default Contact;