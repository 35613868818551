import React from "react";
import './App.css';

import Home from "./components/Home/Home";
import NavigationBar from "./components/NavigationBar/NavigationBar"
import ScrollToTop from "./components/ScrollToTop/scrollToTop"
import FooterSection from "./components/FooterSection/FooterSection";
import TinyNinzaPage from "./components/TinyNinzaPage/TinyNinzaPage";
import TwittyPage from "./components/TwittySection/TwittyPage";
import KettleMind from "./components/KettleMindSection/KettleMind";
import ContactUsPage from "./components/ContactUsPage/ContactUsPage";

import {createBrowserRouter, RouterProvider} from 'react-router-dom'

import PrivacyPolicyPage from './components/PrivacyPolicyPage/PrivacyPolicyPage'
import TermsNCondition from "./components/TermsnConditions/TermsNCondition"

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: 
      <>
        <ScrollToTop />
        <NavigationBar />
        <Home />
        <FooterSection />
      </>
    },
    {
      path: '/tinyninza',
      element:
      <>
        <ScrollToTop />
        <NavigationBar />
        <TinyNinzaPage />
        <FooterSection />
      </>
    },
    {
      path: '/twitty',
      element:
      <>
        <ScrollToTop />
        <NavigationBar />
        <TwittyPage />
        <FooterSection />
      </>
    },
    {
      path: '/kettlemind',
      element:
      <>
        <ScrollToTop />
        <NavigationBar />
        <KettleMind />
        <FooterSection />
      </>
    },
    {
      path: "/privacy-policy",
      element: 
      <>
      <ScrollToTop />
      <NavigationBar />
      <PrivacyPolicyPage />
      <FooterSection />
      </>
    },
    {
      path: '/terms&condition',
      element:
      <>
      <ScrollToTop />
      <NavigationBar />
      <TermsNCondition />
      <FooterSection />
      </>
    },
    {
      path: '/contact-us',
      element:
      <>
      <ScrollToTop />
      <NavigationBar />
      <ContactUsPage />
      </>
    }
    // {
    //   path: "/app-ads.txt",
    //   element: <AdsPageSection />
    // }
  ]);


  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App
