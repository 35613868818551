import React from "react";
import './home.css';

import Banner from "./InnerComponents/Banner";
import AboutUs from "./InnerComponents/AboutUs";
import Products from "./InnerComponents/Products";
import Contact from "./InnerComponents/Contact";


const Home = () =>{
    return(
        <div className="homeSection">
            <Banner />
            <AboutUs />
            <Products />
            <Contact />
        </div>
    );
}

export default Home;