import React from "react";
import Banner from "./InnerComponents/Banner";
import Features from "./InnerComponents/Features";
import Video from "./InnerComponents/Video";

import './tinyNinza.css';

const TinyNinzaPage = () => {
    return(
        <div className="tinyNinzaPage">
            <Banner />
            <Video />
            <Features />
        </div>
    );
}

export default TinyNinzaPage;