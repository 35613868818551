import React from "react";

const Video = () => {
    return(
        <div className="videoSection">
            <p className="videoSectionContent">
                Twitty is a pack of EIGHTEEN preschool games for toddlers which include fun and educational activities to learn alphabets, shapes, numbers,
                letter, colors, crafting, reasoning and many more for kids.
            </p>
            <div className="videoDiv">
                <div className="iFrameDiv">
                <iframe className="iframe" width="962" height="541" src="https://www.youtube.com/embed/7fMPU2-NhW0" title="Twitty - Preschool &amp; Kindergarten Learning Games" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    );
}

export default Video;