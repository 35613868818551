import React from "react";
import './contact.css';
import {useState} from "react";


const ContactUsPage = () => {
    const [data, setData] = useState({
        name: "",
        email: "",
        description: ""
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({...data, [name]: value})
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // const { name, email, description} = data;
        // const res = fetch("https://arg-website-f469f-default-rtdb.firebaseio.com/contactFormData.json",
        // {
        //     method : "POST",
        //     headers: {
        //         "Content-Type" : "application/json",
        //     },
        //     body: JSON.stringify({ name, email, description})
        // });

        // if(res){
        //     setData({
        //         name: "",
        //         email: "",
        //         description: ""
        //     });
        //     alert("Data Stored")
        // } else {
        //     alert("Message didn't sent");
        // }
    }

    return(
        <div className="contactUsPage">
            <h2 className="formTitle">Contact <span>Here</span></h2>
            <div className="formSection">
                <form className="contactForm">
                    <input type="text" placeholder="Enter name" name="name" onChange={handleChange} value = {data.name}/>
                    <input type="email" placeholder="example@gmail.com" name="email" onChange={handleChange} value = {data.email}/>
                    <textarea cols={30} rows={8} placeholder="type here..." name="description" onChange={handleChange} value = {data.description}/>
                    
                    <button className="formBtn" onClick={handleSubmit}><p>SUBMIT</p></button>
                </form>
            </div>
            <div className="infoSection">
                <p>For all your queries and support, please contact us through:</p>
                <p className="mail">publishing@engagingcasualgames4all.in</p>
            </div>
        </div>
    );
}

export default ContactUsPage;