import React from "react";

const Video = () => {
    return(
        <div className="videoSection">
            <p className="videoSectionContent">
                A pack of 40+ unique learning games that cover all Math, Language and cogitive skills.
                TinyNinza comes with mulitplayer and challenge features that build competitiveness.
            </p>
            <div className="videoDiv">
                <div className="iFrameDiv">
                <iframe className="iframe" width="962" height="541" src="https://www.youtube.com/embed/7fMPU2-NhW0" title="Twitty - Preschool &amp; Kindergarten Learning Games" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    );
}

export default Video;