import React from "react";
import './footerSection.css'
import {useNavigate} from 'react-router-dom'

import footerBG from '../../assets/FooterCurvedStrip.png'
import logo from '../../assets/HA_Logo_Original.png'

import {RiFacebookFill} from 'react-icons/ri'
import {AiOutlineGooglePlus} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {FaLinkedinIn} from 'react-icons/fa'
import {CiYoutube} from 'react-icons/ci'



const FooterSection = () => {

    const navigate = useNavigate();
    const handleNavigate = (id) => {
        switch(id){
            case 1:
                navigate('/privacy-policy');
                break;
            case 2:
                navigate('/terms&condition');
                break;
            default:
                navigate('/');
                break;
        }
    }

    return(
        <div className="footerSection">
            <img className="footerImg" src={footerBG} alt="Happy Adda" />
            <div className="footerContent">
                <div className='companyLogo'>
                    <img className='logoImg' src={logo} alt="Happy-Adda"/>
                </div>
                <div className="resourcesSection">
                    <div className="resourcePages">
                        <p className="resourceLink" onClick={()=>handleNavigate(1)}>Privacy Policy</p>
                        <p className="resourceLink" onClick={()=>handleNavigate(2)}>Terms & Conditions</p>
                    </div>
                    <div className="socialIcons">
                        <h3 className="socialIconsHeading">Follow Us</h3>
                        <div className="icons">
                            <div className="iconBox">
                                <RiFacebookFill className="socialIcon"/>
                            </div>
                            <div className="iconBox">
                                <AiOutlineGooglePlus className="socialIcon"/>
                            </div>
                            <div className="iconBox">
                                <AiOutlineTwitter  className="socialIcon"/>
                            </div>
                            <div className="iconBox">
                                <FaLinkedinIn className="socialIcon"/>
                            </div>
                            <div className="iconBox">
                                <CiYoutube className="socialIcon"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>Copyrights</p>
            </div>
        </div>
    );
}

export default FooterSection;