import React from 'react';
import './NavigationBar.css';

import logo from '../../assets/HA_Logo_Original.png'
import { useState} from 'react'
import {useNavigate} from 'react-router-dom'

import { RxHamburgerMenu } from "react-icons/rx"
import { CgClose } from 'react-icons/cg' 
// import { MdNoEncryption } from 'react-icons/md'

const NavigationBar = () => {

  //Navigations
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    switch(id){
        case 1:
            navigate('/');
            break;
        case 2:
            navigate('/tinyninza');
            break;
        case 3:
            navigate('/twitty');
            break;
        case 4:
            navigate('/kettlemind');
            break;
        case 5:
            navigate('/contact-us');
            break;
        default:
            navigate('/');
            break;
    }
  }

  const [closeBtn, setCloseBtn] = useState("menuBtn hidden");
  const [openBtn, setOpenBtn] = useState("menuBtn");
  const [menuOpen, setMenuOpen] = useState("navSection hidden");

  const menuOpenHandle = () => {
    setOpenBtn("menuBtn hidden");
    setCloseBtn("menuBtn");
    setMenuOpen("navSection");
  }

  const menuCloseHandle = () => {
    setOpenBtn("menuBtn");
    setCloseBtn("menuBtn hidden");
    setMenuOpen("navSection hidden");
  }



  return (
    <>
      <nav className="navBar">
        <div className='companyLogo' onClick={()=>handleNavigate(1)}>
          <img className='logoImg' src={logo} alt="Happy-Adda"/>
        </div>
        <div className={menuOpen}>
          <ul className='navList'>
            <div className='listBox'><li className='navItem' onClick={()=>handleNavigate(1)}>Home</li></div>
            <div className='listBox'><li className='navItem' onClick={()=>handleNavigate(2)}>TinyNinza</li></div>
            <div className='listBox'><li className='navItem' onClick={()=>handleNavigate(3)}>Twitty</li></div>
            <div className='listBox'><li className='navItem' onClick={()=>handleNavigate(4)}>KettleMind</li></div>
            <div className='listBox'><li className='navItem' onClick={()=>handleNavigate(5)}>Contact Us</li></div>
          </ul>
        </div>
        <div className={openBtn} onClick={menuOpenHandle}>
            <RxHamburgerMenu className='icon' />
        </div>
        <div className={closeBtn} onClick={menuCloseHandle}>
          <CgClose className='icon' />
        </div>
      </nav>
    </>
  );
}

export default NavigationBar
