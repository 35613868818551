import React from "react";
import './termsNCondition.css';

const TermsNCondition = () =>{
    return(
        <div className="policyContainer">
            <div className="secDiv">
                <p className="sectionTitle">Terms and Conditions</p>
                <p className="sectionContent">
                Happy Adda Studios provides online contents through mobile applications like TinyNinza, KettleMind, Twitty and websites like www.happyadda.com and www.tinyninza.com For the purpose of these terms and conditions (“terms”), we refer to the online content we provide, including the Websites and the Apps as the “services”. These terms apply to us and all users of our services (“you”/”your”). They govern your access to, downloading and use of all the services.
                </p>
                <p className="sectionContent">
                Please read these terms carefully before using any of the services. These are a legal agreement between you and Happy Adda Studios. Using any of the services indicates that you accept these terms. If you do not accept these terms, please do not use the services, leave the Website, cease downloading the App, or leave the App.
                </p>
            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Acknowledgement and Refreshing of these Terms</h2>
                    <p className="sectionContent">
                    By utilizing our administrations, you affirm that you have perused and comprehended these terms and consent to submit to them. You affirm that you are matured enough to go into an authoritative concurrence with us, and if not, that you have the consent of your parent or lawful watchman/guardian, who will assume liability for your consistence with these terms. You warrant that you are the proprietor of the gadget to which you have downloaded any App, or that you have acquired consent from the proprietor of that gadget to download the App. You acknowledge obligation as per these terms for the utilization of Apps on any gadget, regardless of whether that gadget is possessed by you.
                    </p>
                    <p className="sectionContent">
                    Happy Adda Studios may refresh these terms every now and then. In the event that any progressions to the terms are made, we shall inform you. Every now and then, updates to Apps might be issued through your application store supplier. Contingent upon the refresh, you will most likely be unable to utilize an App until the point that you have downloaded the most recent adaptation of the App and acknowledged any new terms.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Fees</h2>
                    <p className="sectionContent">
                    You recognize that Happy Adda Studios maintains whatever authority is needed to charge for any bit of the Service and to change its expenses (assuming any) every once in a while in its circumspection. You consent to pay all charges when due as per the terms put forward on the Site.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Payment through the Service</h2>
                    <p className="sectionContent">
                    You may pay for specific administrations (either through one of our Websites, from inside one of our Apps, or by means of an application store supplier) utilizing an auto-restoring membership. This implies your membership will be naturally recharged toward the finish of every membership period except if you drop it. In case you don’t drop it you will be charged ahead of time for the following membership time frame. Auto memberships can be adjusted or dropped by changing your settings with the fitting application store supplier, or in cases where you acquired the administrations specifically from us, from inside the administration obtained (eg the App), or by reaching us at ping@happyadda.com . But, in case you drop a membership, you can’t guarantee a discount for the period staying until the completion of the membership time frame in which you dropped.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Payment Gateway</h2>
                    <p className="sectionContent">
                    For making all payments for purchases on the website, you shall be required to make a payment for which you will be redirected to a third-party payment gateway that we may have an agreement with. The payment gateway may additionally seek information from you to process the payment for your payment and additionally may charge you the payment gateway charges that may be applicable. The payment gateway consists of Debit Card/Credit Card/Net Banking/UPI and other wallet options. You shall be governed under the concerned payment gateway’s Terms and Conditions and other Policies for the purpose of all payment-related aspects.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Account Creation</h2>
                    <p className="sectionContent">
                    You may need to make a record and furnish us with a password before you can utilize a portion of our administrations. You are in charge of keeping up the privacy of your secret key and account and are completely in charge of all exercises that happen under your secret key or record. Each record must be utilized by you and no one else, and it isn’t transferable. You will instantly advise Happy Adda Studios should you speculate any unapproved utilization of your record or secret phrase. You shall furnish valid, forward and finish data about yourself amid enlistment and guarantee this data stays up and coming.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Content</h2>
                    <p className="sectionContent">
                    All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music, and artwork (collectively, ‘Content’), is generated/provided or based on information provided by the users or third parties and we have no control and make no guarantees regarding the quality, the accuracy, integrity or genuineness of such content or such other information provided on the website. All the Content displayed on the website is subject to copyright and shall not be reused by you (or a third party) without the prior written consent from us and the copyright owner. You are solely responsible for the integrity, authenticity, quality, and genuineness of the content provided on the website and whilst feedback and comments by you can be made via the website, we bear no liability whatsoever for any feedback or comments made by the other users or made in respect of any of the content on the website. Further, the website reserves its right to suspend the account of any user for an indefinite period to be decided at the discretion of the website or to terminate the account of any user who is found to have created or shared or submitted any content or part thereof that is found to be untrue/inaccurate/misleading or offensive/vulgar. You have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the content on the website. You shall not copy, adapt, and modify any content without written permission from us.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">Indemnity</h2>

                <div className="otherdetailsInfoSection">
                    <p className="sectionContent">
                    You agree to indemnify, defend and hold harmless Happy Adda Studios, and our respective directors, officers, employees and agents (collectively, "Parties"), from and against any losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by us that arise out of, result from, or maybe payable by, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed according to these Terms of use. Further, you agree to hold us harmless against any claims made by any third party due to, or arising out of, or in connection with:
                    </p>

                    <ul className="sectionContentList">
                        <li className="sectionListItem">
                        Your use of the website, Application
                        </li>

                        <li className="sectionListItem">
                        Your violation of these Terms of use.
                        </li>

                        <li className="sectionListItem">
                        Your violation of any rights of another.  
                        </li>

                        <li className="sectionListItem">
                        Your alleged improper conduct according to these Terms of use. 
                        </li>

                        <li className="sectionListItem">
                        Your conduct in connection with the website and Applications.  
                        </li>
                    </ul>

                    <p className="sectionContent">
                    You agree to fully cooperate in indemnifying us at your expense. You also agree not to settle with any party without consent from us.
                    </p>

                    <p className="sectionContent">
                    In no event shall we be liable to compensate you or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not you had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with your use of or access to the website and/or the services or materials contained therein.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Limitation Of Liability</h2>

                    <ul className="sectionContentList">
                        <li className="sectionListItem">
                        We are not responsible for any consequences arising out of the following events:
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                If the website or Application is inoperative/non-responsive due to any connectivity errors associated with the internet connection such as but not limited to slow connectivity, no connectivity, server failure.
                                </li>
                                <li className="sectionListItem">
                                If you have fed incorrect information or data or for any deletion of data.                                </li>
                                <li className="sectionListItem">
                                If there is an undue delay or inability to communicate through email.                                </li>
                                <li className="sectionListItem">
                                If there is any deficiency or defect in the services managed by us.                                </li>
                                <li className="sectionListItem">
                                If there is a failure in the functioning of any other service provided by us.
                                </li>
                            </ul> 
                        </li>

                        <li className="sectionListItem">
                        The website accepts no liability for any errors or omissions, on behalf of itself, or for any damage caused to you, your belongings, or any third party, resulting from the use or misuse of the website or Application or any service availed of by us through the website or Application. The service and any Content or material displayed on the service is provided without any guarantees, conditions, or warranties as to its accuracy, suitability, completeness, or reliability. We will not be liable to you for the unavailability or failure of the website.
 
                        </li>

                        <li className="sectionListItem">
                        You are to comply with all laws applicable to you or your activities, and with all Policies, which are hereby incorporated into this Terms of use by reference.  
                        </li>

                        <li className="sectionListItem">
                        The website or Application  expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the website or Application and which is incurred by you in connection with the website or Application, including loss of profits; and any loss or damage incurred by you as a result of your breach of these Terms of use.  
                        </li>

                        <li className="sectionListItem">
                        To the fullest extent permitted by law, we shall not be liable to you or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the website or Application.
                        </li>

                    </ul>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Promotional and Marketing Offers</h2>
                    <p className="sectionContent">
                    We may offer promotional offers from time to time which may include discount codes or account credits, may be subject to expiration dates and may only be applicable to selected users of a service. We reserve the right to withhold or remove credit from a user account or end a promotion without notice if at our sole discretion we believe a user is not acting in good faith in relation to the terms of the promotion.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Links to Third Party Apps and Website</h2>
                    <p className="sectionContent">
                    Any connects to outsider applications and sites are given exclusively to your benefit. We have not checked on these outsider applications and sites and don’t control and are not in charge of these applications/sites or their substance or accessibility. We don’t embrace or make any portrayals about them, or any material found there, or any outcomes that might be gotten from utilizing them. In the event that you choose to get to any of the outsider applications/sites connected to from our administrations, you do as such completely at your own risk.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Privacy</h2>
                    <p className="sectionContent">
                    We are committed to respecting your privacy and the privacy of all individuals using the services. More information regarding how we may use your personal data can be found in our Privacy Policy. By using any of the services, you agree to the terms laid out in our Privacy Policy which is incorporated into these terms by reference.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Communication</h2>
                    <p className="sectionContent">
                    By using this website and providing your identity and contact information to us through the website, you agree and consent to receive calls, e-mails, or SMS from us and/or any of its representatives at any time.
                    </p>
                    <p className="sectionContent">
                    You can report to ping@happyadda.com if you find any discrepancy about website or content-related information and we will take necessary action after an investigation. The response with resolution (if any issues found) shall be dependent on the time is taken for investigation.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Force Majeure</h2>
                    <p className="sectionContent">
                    We will not be liable for damages for any delay or failure to perform our obligations hereunder if such delay or failure is due to cause beyond our control or without its fault or negligence, due to force majeure events including but not limited to acts of war, acts of god, earthquake, riot, fire, festive activities sabotage, labour shortage or dispute, internet interruption, technical failure, breakage of sea cable, hacking, piracy, cheating, illegal or unauthorized.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Dispute Resolution and Jurisdiction</h2>
                    <p className="sectionContent">
                    It is expressly agreed to by you hereto that the formation, interpretation, and performance of these Terms of use and any disputes arising therefrom will be resolved through Arbitration. It is further agreed to by you that the contents of this section shall survive even after the termination or expiry of the Terms of use and/or Privacy Policy.
                    </p>
                    <p className="sectionContent">
                    <span>Arbitration:</span> In the event that the Parties are unable to amicably resolve a dispute, the said dispute will be referred to arbitration by a sole arbitrator to be appointed by us, and the award passed by such sole arbitrator will be valid and binding on all parties. All cost of the Arbitration shall be borne solely in accordance with the Arbitrators award. The arbitration shall be conducted in English, and the seat of Arbitration shall be the city of Bengaluru, Karnataka, India.
                    </p>
                    <p className="sectionContent">
                    You expressly agree that the Terms of use, Privacy Policy and any other agreements entered between the Parties are governed by the laws, rules, and regulations of India.
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">Refund and Cancellation Policy</h2>

                <p className="sectionContent">
                Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will refund back the money, provided the reasons are genuine and proved after investigation.
                </p>
                <p className="sectionContent">
                In case of dissatisfaction from our services, clients have the liberty to cancel their projects and request a refund from us. Our Policy for the cancellation and refund will be as follows:
                </p>

                <div className="otherdetailsInfoSection">

                    <ul className="sectionContentList">
                        <li className="sectionListItem">
                            Cancellation Policy 
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                If you subscribed to TinyNinza, Twitty and Kettlemind through the App Store (iOS) or Google Play (Android) and need to request a refund, please contact them directly. HappyAdda cannot refund purchases made through the App Store or Google Play.
                                </li>
                                <li className="sectionListItem">
                                If you subscribed to TinyNinza, Twitty, Kettlemind through our website, please note that in most cases, subscriptions for TinyNinza, Twitty, Kettlemind are non-refundable. If you think there’s been an error in billing, please contact our support team at ping@happyadda.com and we’ll work with you to find a solution.
                                </li>
                            </ul>
                        </li>

                        <li className="sectionListItem">
                        Refund Policy 
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase and in case of payment gateway name payments refund will be made to the same account.                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">Miscellaneous Provisions</h2>
                <div className="otherdetailsInfoSection">

                    <ul className="sectionContentList">
                        <li className="sectionListItem">
                        <span>Entire Agreement:</span> These Terms of use, read with the Privacy Policy, form the complete and final contract between us with respect to the subject matter hereof and supersedes all other communications, representations, and agreements (whether oral, written or otherwise) relating thereto.
 
                        </li>

                        <li className="sectionListItem">
                        <span>Waiver:</span> The failure at any time to require performance of any provision of these Terms of use shall in no manner affect our right at a later time to enforce the same. No waiver by us of any breach of these Terms of use, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms of use.
                        </li>

                        <li className="sectionListItem">
                        <span>Severability:</span> If any provision/clause of these Terms of use is held to be invalid, illegal, or unenforceable by any court or authority of competent jurisdiction, the validity, legality, and enforceability of the remaining provisions/clauses of these Terms of use shall in no way be affected or impaired thereby, and each such provision/clause of these Terms of use shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms of use shall be reformed to the minimum extent necessary to correct any invalidity, illegality, or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein.  
                        </li>

                    </ul>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">Contact Details</h2>
                    <p className="sectionContent">
                    If you have any queries about these Terms and Conditions, Please Contact us by email at ping@happyadda.com
                    </p>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

        </div>
    );
}

export default TermsNCondition;