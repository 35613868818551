import React from "react";
import banner from '../../../assets/KettleMindBanner.png'


const Banner = () => {
    return(
        <div className="kettleMindBanner">
            <img className="pageBanner" src={banner} alt="KettleMind"/>
        </div>
    );
}

export default Banner;