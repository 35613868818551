import React from "react";
import './PrivacyPolicyPage.css';

const PrivacyPolicyPage = () =>{
    return(
        <div className="policyContainer">
            <div className="secDiv">
                <p className="sectionTitle">PRIVACY POLICY</p>
                <p className="sectionContent">
                Happy Adda Studios (“We”) are committed to protecting and respecting your privacy.
                </p>
                <p className="sectionContent">
                Happy Adda Studios provides online Learning contents through mobile applications like TinyNinza, KettleMind (“Apps”) and websites like www.happyadda.com and https://tinyniza.com (the “Websites”)
                </p>
                <p className="sectionContent">
                By using the Websites and/or using any of our Apps, you consent to us processing your personal information in accordance with this Privacy and Cookie Policy (the “Policy”). This is the legal basis upon which we process your personal information as well. It is necessary for the performance of our obligations under the terms of use/terms and conditions you accept to use our Apps or the Websites. You may withdraw such consent at any time by letting us know at ping@happyadda.com At that time, you may also want to remove any cookies which have been placed on any device used to access the Websites. Your withdrawal of consent will not affect the lawfulness of any processing carried out by us prior to such withdrawal
                </p>
            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">This is the Information We may collect from you:</h2>

                    <ul className="sectionContentList">
                        <li className="sectionListItem">
                            Information you provide if you decide to contact us
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                If you contact us (by phone or email or through an App), we may keep a record of that. If you report a problem with a Website or an App, we may keep that information. The information which you provide and we keep may include your name, address, telephone number and/or email address.
                                </li>
                            </ul> 
                        </li>

                        <li className="sectionListItem">
                        Information we collect about you when you use a Website 
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                When you use a Website, we use technology such as that provided by Google Analytics to collect information about your visit to that Website. This includes the pages you visit and how long you spend on the Website. You can find more information about Google Analytics here: www.google.com/analytics
                                </li>
                            </ul> 
                        </li>

                        <li className="sectionListItem">
                        Information we collect about you when you download an App 
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                We sometimes may ask you for information after you have downloaded an App, but before you start using it. This may include contact details such as your email address, personal details such as your Facebook Id or name, or demographic details such as your gender, date of birth or profession. Some of this information is required (eg email address) and some are optional (eg profession). What we collect is slightly different depending upon whether you choose to login using Facebook or not.
                                </li>
                            </ul> 
                        </li>

                        <li className="sectionListItem">
                        Information we may collect when you use an App 
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                We collect information when you use our Apps on how you use those Apps and how you play the games in them. For example, we will note how you interact with our games, how you perform and what scores you achieve. We also take note of when you choose to share your achievements with friends on Facebook and if you choose to pay for versions of our Apps such as TinyNinza, KettleMind Pro.
                                </li>
                            </ul> 
                        </li>

                        <li className="sectionListItem">
                        Information we may collect about the device you use to access and use an App
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                When you use our Apps we collect information about your device such as its IP address, what model it is and a device ID.
                                </li>
                            </ul> 
                        </li>

                    </ul>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <div className="otherdetailsInfoSection">
                    <h2 className="sectionsubTitle">This is what we do with the information we collect from you:</h2>

                    <ul className="sectionContentList">
                        <li className="sectionListItem">
                        The information you provide if you decide to contact us 
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                We use it to correspond with you and we keep it in case we need to contact you in the future in relation to the issue for which you contacted us.
                                </li>
                            </ul> 
                        </li>

                        <li className="sectionListItem">
                        The information we collect about you when you use a Website 
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                We use Google Analytics because the information it collects enables us to analyse how you and others interact with our Website.
                                </li>
                            </ul> 
                        </li>

                        <li className="sectionListItem">
                        The information we collect about you when you download an App  
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                We collect this information to ensure that you are a real person and not a robot! We use it so that we can personalise our communication and experience when using an App. We may use it to provide you with information about our Apps such as changes we make in them as well as changes to our terms and conditions or this Policy. We use some of the information that you may choose to provide (such as your profession) to enable you to compare your performance in a game with other people like you. We also use the information we collect to analyse performance within a game by a particular demographic such as age.
                                </li>
                                <li className="sectionListItem">
                                We may contact you by electronic mail, using such information. We use it for conveying things, we think you will find useful and interesting such as how you are performing in games, lifestyle tips, news about science and other areas we are active in and to invite you to events. This may include telling you about products or services provided by other companies. We will never give your personal information to such other companies. You may opt out from receiving marketing information from us at any time by using the ‘unsubscribe link’ in the emails we send, or by contacting us at ping@happyadda.com . This will stop us sending you marketing information by email.
                                </li>
                                <li className="sectionListItem">
                                We also at times may send you information within an App using, what are called, push notifications. You may at any time opt-out from receiving such notifications by turning them off in your device’s App settings.                                </li>
                            </ul> 
                        </li>

                        <li className="sectionListItem">
                        Information we collect when you use an App  
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                We use this information to improve our products and make your experience better and more personal, for example, by noting when you do well and allowing you to share your performances with friends.
                                </li>
                            </ul> 
                        </li>

                        <li className="sectionListItem">
                        Information we may collect about the device you use to access and use an App
                            <ul className="sectionContentList second">
                                <li className="sectionListItem">
                                We collect this information, aggregate it and it provides us with demographic information which we then use to, for example, tailor our products to such demographics. It also allows us to watch out for malicious attacks on our servers and maintain our security. Finally, we use a device ID called an IDFA for advertising purposes and to acquire new customers for our products, as explained further in this Policy.
                                </li>
                                <li className="sectionListItem">
                                We ensure you of not using your personal information for any other uses without notifying you first. You will also be given the opportunity to withhold or withdraw your consent for the use of your personal information for purposes other than those listed above.
                                </li>
                            </ul> 
                        </li>

                    </ul>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">This is who we share information we collected:</h2>

                <p className="sectionContent">
                We will only share information with other organisations where we have your permission to do so in accordance with this Policy, where we believe it is necessary for a legitimate reason connected with an App or a Website, or where it is necessary for us to comply with the terms and conditions of that App or Website. We will only share information with a third party if we are satisfied that such third party will keep that information secure.
                </p>
                <p className="sectionContent">
                We work with analytics providers such as Google Analytics which may involve information about your use of a Website and/or an App been processed by Google.                </p>
                <p className="sectionContent">
                We work with Facebook to allow you to sign in for Apps using the credentials that you have with that company. We also enable you to share your performances when using our Apps with your Facebook friends. This involves Facebook processing some of your information. It also results in some of your information becoming public, so do read Facebook’s privacy policy and be careful with what you share.
                </p>

                <div className="otherdetailsInfoSection">
                    <p className="sectionContent">
                    Finally, we may disclose your personal information to third parties:
                    </p>

                    <ul className="sectionContentList">
                        <li className="sectionListItem">
                        if we are under a duty to disclose or share your personal information in order to comply with any legal or regulatory obligation or request;
                        </li>

                        <li className="sectionListItem">
                        to enforce or apply our terms of use/terms and conditions and other agreements or to investigate potential breaches; or
                        </li>

                        <li className="sectionListItem">
                        to protect the rights, property or safety of a Website and/or App, or of our users.  
                        </li>
                    </ul>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">Other Information Collectors</h2>
                <p className="sectionContent">
                Unless otherwise expressly stated in this Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our website or other sites across the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they comply with their own privacy policies. Since we do not control the privacy policies of third parties, you should ask questions before disclosing your personal information to others.
                </p>
            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">Your Rights</h2>

                <div className="otherdetailsInfoSection">
                    <p className="sectionContent">
                    The General Data Protection Regulation("GDPR") gives you certain rights regarding your data. Where available and unless limited by applicable law, the rights granted to users are:
                    </p>

                    <ul className="sectionContentList">
                        <li className="sectionListItem">
                        <span>Access rights -</span> the right to be notified of, and request access to, the personal data we process about you                        
                        </li>

                        <li className="sectionListItem">
                        <span>Right to correct -</span> right to ask us to change or update your data if it is inaccurate or incomplete
                        </li>

                        <li className="sectionListItem">
                        <span>Right to delete -</span> right to ask us to delete your data, We will delete the data within 30 days of receiving written request from user on our email address mentioned on website.
                        </li>

                        <li className="sectionListItem">
                        <span>Right to limit -</span> the right to ask us to temporarily or permanently stop processing all or part of your data
                        </li>

                        <li className="sectionListItem">
                        <span>Right to object -</span> the right, at any time, to refuse us to process your data for reasons related to your situation and the right to object to your data being processed for direct marketing purposes                        </li>

                        <li className="sectionListItem">
                        <span>Right to data portability -</span> the right to request a copy of your data in electronic format and the right to transmit that personal data for use in the services of other parties; and
                        </li>

                        <li className="sectionListItem">
                        The right not to be subject to automated decision making - the right not to be subject to decisions based solely on automated decision making, including profiling, where the decision would have a legal effect on you or produce a similar significant impact                        
                        </li>
                    </ul>
                </div>

                {/* <div className="noteSection">
                    <p className="sectionContent">
                    <span>Note:</span> Some of our games / apps include sharing feature which allows user to share the content with others, it may include personal information. We are not responsible for sharing your personal information with others and that you are doing at your own risk.
                    </p>
                </div> */}

            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">Confidentiality</h2>
                <p className="sectionContent">
                You further acknowledge that the website may contain information which we deem to be confidential and you may not disclose such information without our prior written consent. Your information is considered confidential and therefore will not be disclosed to any third party, unless legally required to do so to the appropriate authorities. We will not sell, share or rent your personal information to any third parties or use your email address for unsolicited mail. Any emails sent by us will only relate to the provision of the agreed services, and you have the sole discretion to request termination of such communications at any time.
                </p>
            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">Our Use of Cookies and other Identifiers</h2>
                <p className="sectionContent">
                Cookies are small pieces of data that are stored on your computer, mobile phone or other device. They do lots of different jobs, like letting you navigate between pages efficiently, storing your preferences, and generally improving your experience of a Website. Cookies make the interaction between you and a Website faster and easier. The only cookies we use are from Google Analytics which distinguish you from other users of the Website. The cookie distinguishing you in this way lasts for two years. This helps us to provide you with a good experience when you use the Website and also allows us to improve the Website. We do not use them on Apps.                
                </p>

                <p className="sectionContent">
                In some cases, we use device identifiers called ‘IDFA’s‘ which operate like cookies. We provide Facebook with an ‘IDFA’ to improve our targeting of ads on Facebook when we are trying to acquire customers for our products.                
                </p>

                <p className="sectionContent">
                Please refer to your device’s help material to learn what controls you can use to remove cookies, block cookies, or disable IDFA tracking. If you do this, it may affect your use of a Website or an App.
                </p>
            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">Disclosure of Your Information</h2>
                <p className="sectionContent">
                Information collected will not be considered sensitive if it is freely available and accessible in the public domain or provided under The Personal Data Protection Act (PDPA), any derivative regulations, or other currently applicable laws.
                </p>

                <p className="sectionContent">
                Due to the scope of existing regulations, we cannot ensure that all of your personal communications and other personally identifiable information will never be disclosed in a manner not described in this Policy. For example (without limiting or eliminating), we may be compelled to disclose information to governments, law enforcement agencies or third parties. Therefore, although we use industry standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or personal communications will always remain confidential. However we assure you that any disclosure of your personally identifiable information will be personally notified to you by email sent to the email address you provided.
                </p>

                <div className="otherdetailsInfoSection">
                    <p className="sectionContent">
                    As part of our policy, we do not sell or rent personally identifiable information about you to any third party. However, the following describes some of the ways your personally identifiable information may be disclosed:
                    </p>

                    <ul className="sectionContentList">
                        <li className="sectionListItem">
                        <span>External Service Providers:</span> There may be several services offered by external service providers that help you use our website. If you choose to use these optional services, and while doing so, disclose information to external service providers, and/or allow them to collect information about you, their use of your information is governed by their privacy policies.                        </li>

                        <li className="sectionListItem">
                        <span>Law Enforcement and Public Order:</span> We cooperate with law enforcement investigations, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We may (and you allow us to) disclose any information about you to law enforcement and other government officials because we, in our sole discretion, believe it is necessary or appropriate, in connection with an investigation of fraud, intellectual property infringement, or other illegal activity or could expose our legal responsibilities.
                        </li>
                    </ul>
                </div>
            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">Keeping Information Secure</h2>
                <p className="sectionContent">
                We make reasonable efforts to protect your Personal Information, but no company, including Happy Adda Studios, can fully eliminate security risks connected to handling information on the internet. Moreover, we can’t be in charge of any of your Personal Information that you discharge with anyone else, or that you ask for or approve us to discharge.
                </p>
            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">Third Party Properties Accessed from Website and/or App</h2>
                <p className="sectionContent">
                Our Websites and Apps may contain links to and from the online properties of third parties. If you follow a link to any of these online properties, please note that these online properties have their own privacy policies which will govern use of any personal data that they process. Please check these policies carefully before you click on any links and/or submit any personal data to these online properties.
                </p>
            </div>

            <div className="secDiv">
                <h2 className="sectionsubTitle">How to Contact Us</h2>
                <p className="sectionContent">
                We truly do welcome any enquiries, remarks, and demands you may have with respect to this Policy. You can get in touch with us by messaging us at ping@happyadda.com 
                </p>
            </div>


        </div>
    );
}

export default PrivacyPolicyPage;