import React from "react";
import Banner from "./InnerComponents/Banner";
import Features from "./InnerComponents/Features";
import Video from "./InnerComponents/Video";
import './kettleMind.css';

const KettleMind = () => {
    return(
        <div className="kettleMind">
            <Banner />
            <Video />
            <Features />
        </div>
    )
}

export default KettleMind;