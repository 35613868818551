import React from "react";
import prod1 from '../../../assets/ProdThumbnail_TN.png'
import prod2 from '../../../assets/ProdThumbnail_Twitty.png'
import prod3 from '../../../assets/ProdThumbnail_KM.png'

const Products = () => {
    return(
        <div className="homeOurProductsSection section">
            <h2 className="sectionTitle">Our Products</h2>
            <div className="products">
                <div className="productDiv">
                    <div className="prodImg">
                        <img className="prodImage" src={prod1} alt="TinyNinza"/>
                    </div>
                    <div className="prodText">
                        <h3 className="prodName">TinyNinza</h3>
                        <p className="prodContent">
                            A pack of 40+ unique learning games thaht cover all Math, Language and cognitive skills.
                        </p>
                        <p className="viewBtn">VIEW MORE</p>
                    </div>
                </div>
                <div className="productDiv">
                    <div className="prodImg">
                        <img className="prodImage" src={prod2} alt="Twitty"/>
                    </div>
                    <div className="prodText">
                        <h3 className="prodName">Twitty</h3>
                        <p className="prodContent">
                            A preschool learning games pack that covers all aspects of toddlers learn.
                        </p>
                        <p className="viewBtn">VIEW MORE</p>
                    </div>
                </div>
                <div className="productDiv">
                    <div className="prodImg">
                        <img className="prodImage" src={prod3} alt="KettleMind"/>
                    </div>
                    <div className="prodText">
                        <h3 className="prodName">KettleMind</h3>
                        <p className="prodContent">
                            KettleMind is game-based brain training and cognitive skill assessment platform.
                        </p>
                        <p className="viewBtn">VIEW MORE</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products;