import React from "react";

import parentapp from '../../../assets/ParentConnectApp.png'
import learningGames from '../../../assets/GameBasedAssignments.png'
import teacher from '../../../assets/Classrooms.png'

const Features = () => {
    return(
        <div className="featuresSection">
            <h1 className="sectionTitle">Features</h1>
            <div className="featuresDiv">
                <div className="feature">
                    <div className="featureImgDiv">
                        <img className="featureImg" src={parentapp} alt="Parent Connect App"/>
                    </div>
                    <div className="featureTextDiv">
                        <p className="featureTitle">Practice and Assess cognitive skills with fun</p>
                        <p className="featureContent">
                            Kickstart your brain with fun exercises. Our well designed 23 fun games are made for all the generations.
                            They flex your brain, and also improve your cognitive skills like Memory, Focus, Visual, Reasoning, Problem Solving, English.
                        </p>
                    </div>
                </div>
                <div className="feature">
                    <div className="featureImgDiv">
                        <img className="featureImg" src={learningGames} alt="Learning Games"/>
                    </div>
                    <div className="featureTextDiv">
                        <p className="featureTitle">Track your performance</p>
                        <p className="featureContent">
                            Understand how good you are at different skills and track your progress overtime. As you train, the program will adjust to your skills.
                            <br />
                            Track your cognitive performance with detailed progress tracking, actionable feedback, and rich insightful statistics.
                        </p>
                    </div>
                </div>
                <div className="feature">
                    <div className="featureImgDiv">
                        <img className="featureImg" src={teacher} alt="Teacher Dashboard"/>
                    </div>
                    <div className="featureTextDiv">
                        <p className="featureTitle">Play with Friends</p>
                        <p className="featureContent">
                            Challenge your friends to compete and compare on different cognitive skills.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Features;