import React from "react";
import Banner from "./InnerComponents/Banner";
import Features from "./InnerComponents/Features";
import Video from "./InnerComponents/Video";

import './twitty.css';

const TwittyPage = () => {
    return(
        <div className="twittyPage">
            <Banner />
            <Video />
            <Features />
        </div>
    );
}

export default TwittyPage;