import React from "react";
import banner from '../../../assets/New/HomeBanner_1.png'


const Banner = () => {
    return(
        <div className="homeBannerSection">
            <img className="bannerImg" src={banner} alt="Happy Adda"/>
        </div>
    )
}

export default Banner;